<template>
  <div>
    <ObjectsWorksProgress v-if="blockName == 'objectsWorksProgress'" :config="config" :height="height" />
    <TaskOther v-if="blockName == 'taskOther'" :height="height" @click="onClickTask($event)" :rows="getTasks('other')" />
    <TaskLate v-if="blockName == 'taskLate'" :height="height" @click="onClickTask($event)" :rows="getTasks('late')" />
    <TaskWork v-if="blockName == 'taskWork'" :height="height" @click="onClickTask($event)" @config="$emit('onClickConfig', $event)" :rows="getTasks('work')" />
    <TaskGoods v-if="blockName == 'taskGoods'" :height="height" @click="onClickTask($event)" @config="$emit('onClickConfig', $event)" :rows="getTasks('goods')" />
    <LastReport v-if="blockName == 'lastReport'" :height="height" />
    <ActWork v-if="blockName == 'actWork'" :height="height" />
    <ActWorkClient v-if="blockName == 'actWorkClient'" :height="height" />
    <NewWork v-if="blockName == 'newWork'" :height="height" />
    <DocAlert v-if="blockName == 'docAlert'" :height="height" />
    <DocStatus v-if="blockName == 'docStatus'" :height="height" />
    <Cash v-if="blockName == 'cash'" :height="height" />
    <LastCashIncome v-if="blockName == 'lastCashIncome'" :config="config" :height="height" />
    <FuturePayment v-if="blockName == 'featurePayment'" :config="config" :height="height" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    <s-quickOpenDoc ref="quickOpenDoc" />
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    TaskGoods: () => import("./tasksTodayGoods"),
    TaskWork: () => import("./tasksTodayWork"),
    TaskLate: () => import("./tasksTodayLate"),
    TaskOther: () => import("./tasksTodayOther"),
    LastReport: () => import("./lastReport"),
    ActWork: () => import("./actWork"),
    ActWorkClient: () => import("./actWorkClient"),
    NewWork: () => import("./newWork"),
    DocAlert: () => import("./dashDocAlert"),
    DocStatus: () => import("./dashDocStatus"),
    LastCashIncome: () => import("./lastCashIncome"),
    FuturePayment: () => import("./futurePayment"),
    ObjectsWorksProgress: () => import("./ObjectsWorksProgress"),
    Cash: () => import("./dashCash"),
  },
  props: {
    height: { type: Number, default: 0 },
    blockName: { type: String, default: "" },
    config: { type: Object, default: () => {} },
    data: Array,
  },
  methods: {
    getTasks(s) {
      return this.data.filter(t => t.section == s);
    },
    onClickTask(e) {
      if (["taskOther", "taskLate", "taskWork"].includes(this.blockName)) {
        console.log(this.blockName, e);
        this.$refs.quickOpenDoc.openDocument("Tasks", parseInt(e.id), e.row);
      } else if (["taskGoods"].includes(this.blockName)) {
        console.log(this.blockName, e);
        this.$refs.quickOpenDoc.openDocument("Buying", parseInt(e.id), e.row);
      }
      if (e?.row?.section == "late" && false) {
        console.log("show popup ");

        this.showPopupMenu("task_id", e.row, e.event, e);
        return;
      }
    },
  },
};
</script>

<style></style>
